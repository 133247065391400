.pagination-container {
  margin-top: 16px;
  border-top: 1px solid #DDD;
  margin-bottom: 16px;
  padding: 8px;
  text-align: center;
  color: gray;
  font-size: 0.8em;
}

@media all and (-ms-high-contrast:none) {
  body { background-color: green } /* IE10 */
  *::-ms-backdrop, :not(.vertical).ui.menu .left.item, :not(.vertical).ui.menu :not(.dropdown) > .left.menu { padding-top: 65px; } /* IE11 */
}

.hidden {
  display: none;
}

.main-menu {
  width: 100%;
}

.main-container {
  min-height: 100vh;
}

.wrapper {
  min-height: auto;
  margin-top: 64px;
  padding: 16px;
}

.wrapper > .content {
  overflow: auto;
  position: absolute;
  top: 0;
  left: 276px;
  height: 100%;
  width: calc(100% - 300px);
}

.full-height {
  margin-top: 0px !important;
  min-height: 100%;
  height: 100vh;
}

.header-page {
  margin-top: 16px !important;
  margin-bottom: 8px !important;
}

.item-link {
  cursor: pointer !important;
}

.input-error {
  border: 1px solid red !important;
}

.edit-inline {
  cursor: pointer;
}

.edit-inline:hover {
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-decoration-color: #F44336;
}
