.time-used {
  margin-right: 10px;
  line-height: 2em;
  width: 100%;
}

.container-time-used {
  text-align: center;
  width: 100% !important;
}

.action-activity {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.action-activity button {
  margin-right: 16px;
}

.mode {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: normal;
}

.mode span {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  width: 28px;
  text-align: center;
  border: 1px solid rgba(112, 112, 112, 0.07);
}

.mode span.selected {
  background-color: var(--secondary);
  color: white;
}

.label-info {
  color: rgba(34, 36, 38, 0.48);
  font-size: 17px;
}

.color-red {
  color: red;
}

.color-blue {
  color: blue;
}

.timer-date {
  margin-top: 32px;
  font-weight: bold;
}

.timer-list-description,
.timer-list-client,
.timer-list-project,
.timer-list-type {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.timer-list-project {
  font-weight: bold;
}

.timer-list-action {
  text-align: right;
}

.text {
  word-wrap: anywhere;
}
