.item .content {
  background-color: transparent !important;
}

#editor > div,
#editor .DraftEditor-root,
#editor .DraftEditor-editorContainer,
#editor .public-DraftEditor-content{
  min-height: 200px;
}

.ui.feed .event {
  padding: 10px;
}

.item-feed {
  padding: 10px;
  margin-bottom: 10px;
}

.item.item-feed.internal {
  background-color: #ff00000d;
}

.ui.feed .content {
  background-color: transparent !important;
}

.interaction-activity {
  background-color: #607d8b14;
  padding: 15px;
}

.CircularProgressbar {
  height: 32px;
}
