#page-interaction-show {
  line-height: 2em;
}

#page-interaction-show p {
  margin-bottom: 0 !important;
}

#page-interaction-show .container-title {
  border-left: 9px solid #2e4765;
  margin-bottom: 20px;
  padding-left: 12px;
}

#page-interaction-show .list-content {
  max-height: 280px;
  overflow:auto; 
}

#page-interaction-show .container-content {
  margin: 20px 0px ;
}

#page-interaction-show .container-content .text-interaction{
  margin: 7px 0px ;
}

#page-interaction-show .container-content .date-interaction{
  font-size: 12px;
}