.container-inner {
  margin-bottom: 40px;
}

#page-sales-show {
  line-height: 2em;
}

#page-sales-show .header-page {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

#page-sales-show .header-page .header-column-left {
  align-items: center;
  display: flex;
  width: 50%;
}

#page-sales-show .header-page .header-column-right {
  align-items: center;
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

#page-sales-show .header-page .header-column-left h1 {
  margin: 0 10px 0 0;
}

#page-sales-show .header-page .header-column-left .sales-number {
  background-color: #84e0be !important;
  border: 1px solid #00bf78;
  color: white;
  font-size: 13px;
  font-weight: 900;
}

#page-sales-show .row b {
  font-weight: 900;
}

#page-sales-show .row b + span {
  margin-left: 10px;
}

#page-sales-show .card-payment {
  background-color: #486e7d !important;
  color: white;
  font-size: 16px;
  height: 100%;
  padding: 22px;
}

#page-sales-show p {
  margin-bottom: 0 !important;
}

#page-sales-show .card-payment-label {
  font-weight: 300;
}

#page-sales-show .card-payment-value {
  font-weight: 700;
  word-break: break-all;
}

#page-sales-show .container-title {
  border-left: 9px solid #2e4765;
  margin-bottom: 20px;
  padding-left: 12px;
}

#page-sales-show .all-content{
  display: inline-flex;
  width: 100%;
}

#page-sales-show .all-content .container-left{
  margin-right: 50px;
}


#page-sales-show  .container-wrapper {
  margin-top: 80px ;
  width: 50%;
}

#page-sales-show .container-participants .user-icon {
  color: #2e4765;
  font-size: 24px !important;
  height: 28px;
  margin-right: 14px;
}

#page-sales-show .list-item {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

#page-sales-show .list-item .list-item-icon span {
  align-items: center;
  background-color: #2e476534;
  border-radius: 10px;
  display: flex;
  float: left;
  justify-content: center;
  margin-right: 20px;
  padding: 5px;
}

#page-sales-show .list-item .list-item-icon-icon {
  color: white;
  font-size: 24px;
  height: 28px;
  line-height: 28px;
  margin: 0;
}


#page-sales-show .list-item .list-content {
  width: 100%;
}

#page-sales-show .list-item .list-content h1 {
  font-size: 19px;
  margin-bottom: 0px!important;
}

#page-sales-show .list-item .list-content .description-row {
  font-size: 12px;
  line-height: 1.6em;
  margin-bottom: 3px;
}

#page-sales-show .list-item .list-content .description-row .label-type {
  background-color: #ffbc6e;
  color: white;
}

#page-sales-show .list-item-right,
#page-sales-show .list-footer-total-price {
  margin-left: 20px;
  width: 300px;
}

#page-sales-show .list-item-right p {
  font-size: 13px;
}

#page-sales-show .list-item-right h1 {
  font-size: 23px;
}

#page-sales-show .list-item-right .auction-button {
  background-color: #84e0be;
  border: 1px solid #00bf78;
  border-radius: 12px;
  color: white;
  padding: 16px;
}

#page-sales-show .list-footer {
  align-items: center;
  background-color: #2e476534;
  display: flex;
  font-size: 22px;
  justify-content: space-between;
  padding: 20px 0 20px 20px;
}

#page-sales-show .list-footer .list-footer-total-itens {
  width: 100%;
}


#page-sales-show .list-content {
  max-height: 300px;
  overflow:auto; 
}