#page-order-show {
  line-height: 2em;
}

#page-order-show .header-page .header-column-left .order-number {
  background-color: #84e0be !important;
  border: 1px solid #00bf78;
  color: white;
  font-size: 13px;
  font-weight: 900;
}

#page-order-show .header-page .header-column-left {
  align-items: center;
  display: flex;
  width: 50%;
}

#page-order-show .header-page .header-column-right {
  align-items: center;
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

#page-order-show .header-page .header-column-left h1 {
  margin: 0 10px 0 0;
}

#page-order-show .row b {
  font-weight: 900;
}

#page-order-show .row b + span {
  margin-left: 10px;
}

#page-order-show .card-payment {
  background-color: #486e7d !important;
  color: white;
  font-size: 16px;
  height: 100%;
  padding: 22px;
}

#page-order-show p {
  margin-bottom: 0 !important;
}

#page-order-show .card-payment-label {
  font-weight: 300;
}

#page-order-show .card-payment-value {
  font-weight: 700;
  word-break: break-all;
}

#page-order-show .container-title {
  border-left: 9px solid #2e4765;
  margin-bottom: 20px;
  padding-left: 12px;
}

#page-order-show .container-wrapper {
  margin-top: 50px;
}

#page-order-show .container-participants .user-icon {
  color: #2e4765;
  font-size: 24px !important;
  height: 28px;
  margin-right: 14px;
}

#page-order-show .list-item {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

#page-order-show .list-item .list-item-icon span {
  align-items: center;
  background-color: #2e476534;
  border-radius: 10px;
  display: flex;
  float: left;
  justify-content: center;
  margin-right: 20px;
  padding: 5px;
}

#page-order-show .list-item .list-item-icon-icon {
  color: white;
  font-size: 24px;
  height: 28px;
  line-height: 28px;
  margin: 0;
}

#page-order-show .list-item .list-content {
  width: 100%;
}

#page-order-show .list-item .list-content h1 {
  font-size: 24px;
}

#page-order-show .list-item .list-content .description-row {
  margin-top: 12px;
}

#page-order-show .list-item .list-content .description-row .label-type {
  background-color: #ffbc6e;
  color: white;
}

#page-order-show .list-item-right,
#page-order-show .list-footer-total-price {
  margin-left: 20px;
  width: 300px;
}

#page-order-show .list-item-right h1 {
  font-size: 32px;
}

#page-order-show .list-item-right .auction-button {
  background-color: #84e0be;
  border: 1px solid #00bf78;
  border-radius: 12px;
  color: white;
  padding: 16px;
}

#page-order-show .list-footer {
  align-items: center;
  background-color: #2e476534;
  display: flex;
  font-size: 22px;
  justify-content: space-between;
  padding: 20px 0 20px 20px;
}

#page-order-show .list-footer .list-footer-total-itens {
  width: 100%;
}

.list-content {
  max-height: 300px;
  overflow: auto;
}

.btn-status {
  background: #e0e1e2 none;
  z-index: 3;
  border-radius: 5px;
  padding: 4px 11px;
  margin-right: 5px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
}
