.coins{
  background: #E0E1E2 none;
  z-index: 3;
  border-radius: 5px;
  padding: 10px 11px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
}


.imput{
  width: 100%s;
}