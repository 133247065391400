.container-inner {
  margin-bottom: 40px;
}

#page-opportunity-show {
  line-height: 2em;
}

#page-opportunity-show .header-page {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

#page-opportunity-show .header-page .header-column-left {
  align-items: center;
  display: flex;
  width: 50%;
}

#page-opportunity-show .header-page .header-column-right {
  align-items: center;
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

#page-opportunity-show .header-page .header-column-left h1 {
  margin: 0 10px 0 0;
}

#page-opportunity-show .header-page .header-column-left .opportunity-number {
  background-color: #84e0be !important;
  border: 1px solid #00bf78;
  color: white;
  font-size: 13px;
  font-weight: 900;
}

#page-opportunity-show .row b {
  font-weight: 900;
}

#page-opportunity-show .row b + span {
  margin-left: 10px;
}

#page-opportunity-show .card-payment {
  background-color: #486e7d !important;
  color: white;
  font-size: 16px;
  height: 100%;
  padding: 22px;
}

#page-opportunity-show p {
  margin-bottom: 0 !important;
}

#page-opportunity-show .card-payment-label {
  font-weight: 300;
}

#page-opportunity-show .card-payment-value {
  font-weight: 700;
  word-break: break-all;
}

#page-opportunity-show .container-title {
  border-left: 9px solid #2e4765;
  margin-bottom: 20px;
  padding-left: 12px;
}

#page-opportunity-show .all-content{
  display: inline-flex;
  width: 100%;
}

#page-opportunity-show .all-content .container-left{
  margin-right: 50px;
}


#page-opportunity-show  .container-wrapper {
  margin-top: 80px ;
  width: 50%;
}

#page-opportunity-show .container-participants .user-icon {
  color: #2e4765;
  font-size: 24px !important;
  height: 28px;
  margin-right: 14px;
}

#page-opportunity-show .list-item {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

#page-opportunity-show .list-item .list-item-icon span {
  align-items: center;
  background-color: #2e476534;
  border-radius: 10px;
  display: flex;
  float: left;
  justify-content: center;
  margin-right: 20px;
  padding: 5px;
}

#page-opportunity-show .list-item .list-item-icon-icon {
  color: white;
  font-size: 24px;
  height: 28px;
  line-height: 28px;
  margin: 0;
}


#page-opportunity-show .list-item .list-content {
  width: 100%;
}

#page-opportunity-show .list-item .list-content h1 {
  font-size: 19px;
  margin-bottom: 0px!important;
}

#page-opportunity-show .list-item .list-content .description-row {
  font-size: 12px;
  line-height: 1.6em;
  margin-bottom: 3px;
}

.description-middle{
  display: flex;
  padding: 0px 0px 5px 0px;
}

.description-middle-right{
  margin-left: 30px;
}

#page-opportunity-show .list-item .list-content .description-row .label-type {
  background-color: #ffbc6e;
  color: white;
}

#page-opportunity-show .list-item-right,
#page-opportunity-show .list-footer-total-price {
  margin-left: 20px;
  width: 300px;
}

#page-opportunity-show .list-item-right p {
  font-size: 13px;
}

#page-opportunity-show .list-item-right h1 {
  font-size: 23px;
}

#page-opportunity-show .list-item-right .auction-button {
  background-color: #84e0be;
  border: 1px solid #00bf78;
  border-radius: 12px;
  color: white;
  padding: 16px;
}

#page-opportunity-show .list-footer {
  align-items: center;
  background-color: #2e476534;
  display: flex;
  font-size: 22px;
  justify-content: space-between;
  padding: 20px 0 20px 20px;
}

#page-opportunity-show .list-footer .list-footer-total-itens {
  width: 100%;
}


#page-opportunity-show .list-content {
  max-height: 300px;
  overflow:auto; 
}

.button-itens{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.btn-status{
    background: #E0E1E2 none;
    z-index: 3;
    border-radius: 5px;
    padding: 4px 11px;
    margin-right: 5px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 700;
}


/* KAMBAM */

.card-tittle{
  text-align: center;
  padding: 13px 5px;
  margin-bottom: 10px;
  background-color: rgb(69, 108, 134);
  color: white;
  box-shadow: 1px 3px 8px 2px rgba(69,108,134,0.36);
}

.card-tittle h2{
 font-size: 18px;
  /* padding: 5px; */
  margin: 5px 0px;
}

.card-value{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 15px;
}
