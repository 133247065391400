.main-menu {
  background-color:  var(--primary) !important;
  border-radius: 0 !important;
  padding-bottom: 100px;
}

.navslide {
  margin-left: 190px;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
}

.menu-divider-label {
  font-weight: bold;
  text-transform: uppercase;
  padding: 8px;
}

.main-menu .item {
  color: #ffffff !important;
}

.main-menu .item.active {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.fixed.main-menu,
.ui.top.fixed.menu {
  height: 66px;
}

.ui.top.fixed.menu {
  border: 0px !important;
}

.ui.thin.left.sidebar.main-menu {
  height: calc(100% - 66px) !important;
  width: 100% !important;
}

.content-logo{
  display: flex;
  align-items: center;
}

.logo-text {
  color: #FFFFFF;
  font-size: 18px;
  margin-left: 10px;
  text-align: center;
  font-weight: bold;
}

.ui.segment {
  border-radius: 0px !important;
  border: 0px !important;
}
