* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

body {
  padding: 0;
  margin: 0
}

#notfound {
  position: relative;
  height: calc(100vh - 100px);
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.notfound {
  max-width: 520px;
  width: 100%;
  text-align: center;
  line-height: 1.4
}

.notfound .notfound-404 {
  height: 190px
}

.notfound .notfound-404 h1 {
  font-family: montserrat, sans-serif;
  font-size: 146px;
  font-weight: 700;
  margin: 0;
  color: #232323
}

.notfound .notfound-404 h1>img {
  display: inline-block;
  width: 120px;
  height: 120px;
  background-size: cover;
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4);
  z-index: -1
}

.notfound h2 {
  font-family: montserrat, sans-serif;
  font-size: 22px;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
  color: #232323
}

.notfound p {
  font-family: montserrat, sans-serif;
  color: #787878;
  font-weight: 300
}

.notfound a {
  font-family: montserrat, sans-serif;
  display: inline-block;
  padding: 12px 30px;
  font-weight: 700;
  background-color: var(--primary);
  color: #fff;
  border-radius: 40px;
  text-decoration: none;
  -webkit-transition: .2s all;
  transition: .2s all
}

.notfound a:hover {
  opacity: .8
}

@media only screen and (max-width:767px) {
  .notfound .notfound-404 {
      height: 115px
  }
  .notfound .notfound-404 h1 {
      font-size: 86px
  }
  .notfound .notfound-404 h1>img {
      width: 86px;
      height: 86px
  }
}
