body {
  overflow-y: hidden;
}

body .wrapper {
  margin-top: 0px;
}

.ui.grid {
  min-height: 100%;
}

.logo {
  margin: 0 auto;
}

.fragment-content {
  margin: -16px !important;
}

.fragment-content .ui > .row {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.bg-blue {
  position: absolute;
  top: 0;
  z-index: 999;
  background-color: var(--primary);
  width: 100%;
  height: 100%;
  opacity: 0.9;
}

.content-login {
  display: flex !important;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.form-login {
  width: 100%;
  padding: 40px;
}

.content-text {
  padding-top: 50%;
  padding-left: 10%;
  padding-right: 10%;
}

.content-text h2 {
  font-size: 35px;
  font-weight: bold;
  color: #ffffff;
}

.content-text p {
  color: #ffffff;
  font-weight: 100;
}
